import React from "react";

import { Avatar, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import humanize from "humanize-react";

import Social from "../../social";

const Authors = ({ authors, ...props }) => {
	if (authors?.length == 1) {
		return <SingleAuthor author={authors[0]} {...props} />;
	}

	return <MultipleAuthors authors={authors} {...props} />;
};

const MultipleAuthors = ({ authors, dateFormatted }) => {
	const classes = useAuthorStyles();

	return (
		<div className={`p-author h-card ${classes.container}`}>
			{authors.map(author => (
				<Avatar
					key={author.id}
					src={author.avatar.fixed.src}
					alt={author.name}
					title={author.name}
					className={`u-photo ${classes.pic} ${classes.picMulti}`}
				/>
			))}

			<MultiByline
				authors={authors}
				dateFormatted={dateFormatted}
				className={classes.byline}
			/>
		</div>
	);
};

const SingleAuthor = ({ author, dateFormatted }) => {
	const classes = useAuthorStyles();

	return (
		<div className={`p-author h-card ${classes.container}`}>
			<Avatar
				src={author.avatar.fixed.src}
				alt={author.name}
				className={`u-photo ${classes.pic}`}
			/>

			<SingleByline {...author} dateFormatted={dateFormatted} />
		</div>
	);
};

const useAuthorStyles = makeStyles(theme => ({
	container: {
		borderTop: `solid 1px ${theme.palette.divider}`,
		marginTop: theme.spacing(3),
		paddingTop: theme.spacing(3),
		[theme.breakpoints.up("sm")]: {
			display: "flex"
		},

		[theme.breakpoints.down("xs")]: {
			textAlign: "center"
		},

		[theme.breakpoints.down("xs")]: {
			flexWrap: "wrap",
			justifyContent: "center"
		},

		"&:hover $picMulti": {
			marginRight: theme.spacing(0.5)
		}
	},
	byline: {
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(2)
		}
	},
	pic: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginRight: theme.spacing(2),

		[theme.breakpoints.down("xs")]: {
			display: "inline-block",
			float: "none",
			marginRight: 0
		}
	},
	picMulti: {
		marginRight: theme.spacing(-5),

		transitionProperty: "margin-right",
		transitionDuration: "0.5s",

		"&:last-of-type": {
			marginRight: theme.spacing(2)
		}
	}
}));

const SingleByline = ({ name, site, dateFormatted, className, ...author }) => (
	<section className={className}>
		<Typography variant="h6">
			Written by <NameLink {...{ name, site }} />
		</Typography>
		<Typography variant="subtitle2">Published on {dateFormatted}</Typography>
		<Social {...author} />
	</section>
);

const MultiByline = ({ authors, dateFormatted, className }) => (
	<section className={className}>
		<Typography variant="h6">
			Written by{" "}
			{humanize(
				authors.map(({ id, name, site }) => (
					<NameLink key={id} {...{ name, site }} />
				))
			)}
		</Typography>
		<Typography variant="subtitle2">Published on {dateFormatted}</Typography>
	</section>
);

const NameLink = ({ name, site }) => {
	if (!site) return <>{name}</>;

	return (
		<Link href={site} color="inherit" className="u-url p-name">
			{name}
		</Link>
	);
};

export default Authors;
