import React from "react";

import Img from "gatsby-image";

import { Hidden, Typography, Link, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import humanize from "humanize-react";

const Cover = ({ cover, ...props }) => {
	const fluidImage = cover?.fluid;

	const classes = useStyles({ hasImage: !!fluidImage });

	if (!fluidImage) return <Header classes={classes} {...props} />;

	return (
		<div className={`${classes.container} ${classes.height}`}>
			<div className={classes.screen}>
				<Header classes={classes} {...props} />
			</div>
			<Img fluid={fluidImage} className={classes.height} />
		</div>
	);
};

const Header = ({
	classes,
	title,
	authors,
	permaLink,
	date,
	dateFormatted,
	timeToRead,
	coverCredit,
	coverCreditLink
}) => (
	<Container maxWidth="md">
		<header className={classes.postHeader}>
			<Typography variant="h3" component="h1" className="p-name">
				{title}
			</Typography>
			<Typography variant="subtitle2" className={classes.meta}>
				<Hidden xsDown>
					<>
						{authors?.length ? (
							<>By {humanize(authors.map(({ name }) => <>{name}</>))} on </>
						) : null}
						<Link href={permaLink} color="inherit" className="u-url">
							<time dateTime={date} className="dt-published">
								{dateFormatted}
							</time>
						</Link>
					</>
				</Hidden>

				<span className={classes.readingTime}>{timeToRead} min read</span>
			</Typography>
			{coverCredit ? (
				<Typography variant="caption" className={classes.credit}>
					Photo by{" "}
					{coverCreditLink ? (
						<Link href={coverCreditLink} color="inherit">
							{coverCredit}
						</Link>
					) : (
						<>{coverCredit}</>
					)}
				</Typography>
			) : null}
		</header>
	</Container>
);

const useStyles = makeStyles(theme => ({
	container: {
		position: "relative",
		marginBottom: theme.spacing(3),

		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(5) // make room for mobile menu
		}
	},
	height: {
		maxHeight: theme.spacing(60)
	},
	screen: {
		position: "absolute",
		background: "rgba(0,0,0,0.5)",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 10,

		display: "flex",
		[theme.breakpoints.up("sm")]: {
			alignItems: "center"
		}
	},
	postHeader: {
		margin: theme.spacing(2, 0),
		[theme.breakpoints.up("sm")]: {
			margin: theme.spacing(6, 0)
		},
		color: ({ hasImage }) => hasImage ? "#fff" : undefined
	},
	meta: {
		borderTop: ({ hasImage }) =>
			!hasImage ? `solid 1px ${theme.palette.divider}` : undefined,
		color: ({ hasImage }) =>
			!hasImage ? theme.palette.text.secondary : undefined
	},
	credit: {
		opacity: 0.5,
		"&:hover": {
			opacity: 0.8
		},
		position: "absolute",
		bottom: theme.spacing(1),
		right: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			bottom: theme.spacing(2)
		}
	},
	readingTime: { display: "block" }
}));

export default Cover;
