import React from "react";
import { graphql } from "gatsby";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Mdx } from "@runly/gatsby-theme";

import { UltraReadingThemeProvider } from "../../theme";
import Layout from "../../layout";
import Seo from "../../seo";

import Cover from "./cover";
import Authors from "./authors";

const BlogPostTemplate = ({
	location,
	data: {
		site: {
			siteMetadata: { siteUrl }
		},
		blogPost
	}
}) => {
	const classes = useStyles();

	return (
		<Layout location={location} maxWidth="md" isOnHero={!!blogPost.cover}>
			<Seo
				title={blogPost.title}
				description={blogPost.excerpt}
				image={blogPost.cover?.fixed.src}
				article={{ published: blogPost.date }}
			/>

			<UltraReadingThemeProvider>
				<article className="h-entry">
					<Cover permaLink={`${siteUrl}${blogPost.slug}`} {...blogPost} />

					<Container maxWidth="md">
						<main role="main" className={`e-content ${classes.postBody}`}>
							<Mdx>{blogPost.body}</Mdx>
						</main>

						<footer className={classes.postFooter}>
							<Authors
								authors={blogPost.authors}
								dateFormatted={blogPost.dateFormatted}
							/>
						</footer>
					</Container>
				</article>
			</UltraReadingThemeProvider>
		</Layout>
	);
};

const useStyles = makeStyles(theme => ({
	postFooter: {
		marginTop: theme.spacing(6)
	},
	meta: {
		borderTop: `solid 1px ${theme.palette.divider}`,
		color: theme.palette.text.secondary
	},
	readingTime: {
		float: "right"
	}
}));

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		blogPost(slug: { eq: $slug }) {
			title
			slug
			date
			dateFormatted: date(formatString: "MMMM Do, YYYY")
			cover {
				fixed(width: 800) {
					...GatsbyImageSharpFixed
				}
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid
				}
			}
			coverCredit
			coverCreditLink
			authors {
				id
				name
				site
				github
				twitter
				avatar {
					fixed(width: 150) {
						...GatsbyImageSharpFixed
					}
				}
			}
			body
			excerpt
			timeToRead
		}
	}
`;
